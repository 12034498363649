<template>
  <div class="container-fluid">
      <div class="row"
           v-if="liveBroadcast != null">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <div class="media-container">
            <div class="streaming-frame"
                 v-html="liveBroadcast"
                 :style="{height: `${mediaContainerHeight}px` }"
            >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <chat :channel="pnChannel"
                :uuid="uuid"
                :height="mediaContainerHeight"
                :isSubscribed="isSubscribed"
                :pnInstance="pnInstance"
          />
        </div>
      </div>
      <div class="streaming-container" v-else>
        <div class="no-event text-center">
          <img class="img-fluid"
               src="../../assets/images/h3_cover.jpg"
               alt="h3-logo" />
          <p class="big mb-0">Oh Snap!</p>
          <p class="medium">No live streaming event.</p>
        </div>
      </div>

  </div>
</template>

<script>
  const Chat = () => import('@/components/user/broadcast/Chat');
  const Hashids = require('hashids');
  import MyMediaMixin from '@/mixins/MyMediaMixin';

  export default {
    name : 'LiveStreaming',
    data() {
      return {
        liveBroadcast : null,
        pnChannel     : 'LiveBroadcast',
        uuid          : null,
        isSubscribed  : false,
        pnInstance    : 'liveStreaming',
      };
    },
    components : {
      Chat,
    },
    created() {
      const hashids = new Hashids.default('', 10);
      this.uuid = hashids.encode(this.$store.getters['user/user'].user_id);
      this.$pnGetInstance(this.pnInstance).setUUID(this.uuid);
    },
    computed : {
      liveBroadcastLink() {
        return this.$store.getters['broadcast/liveBroadcastLink'];
      },
    },
    watch : {
      liveBroadcastLink(value) {
        this.showVideo(value);
      },
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'livestream',
        'platform' : 'website',
        'user_id'  : this.$store.getters['user/user'].user_id,
      });
      this.showVideo(this.liveBroadcastLink);
      this.$pnSubscribe({
        channels     : [this.pnChannel],
        withPresence : true,
      }, this.pnInstance);
    },
    methods : {
      showVideo(value) {
        if (value) {
          const embed = value.replace(
            'width="640" height="360"',
            'style="width: 100%; height: 100%;"',
          );
          this.isSubscribed = true;
          this.liveBroadcast = embed;
        } else
          this.liveBroadcast = null;
        
      },
    },
    mixins : [
      MyMediaMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/live-streaming";
</style>
